// src/components/Login.js

import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast'; // Import toast
import Cookies from 'js-cookie'; // Import js-cookie

const translateError = (error) => {
  switch (error.code) {
    case 'auth/invalid-credential':
      return "Email or Password is incorrect.";
    case 'auth/invalid-email':
      return "Invalid email format.";
    case 'auth/user-disabled':
      return "This user account has been disabled.";
    case 'auth/too-many-requests':
      return "Too many login attempts. Please try again later.";
    default:
      return "An unexpected error occurred. Please try again.";
  }
};

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    const auth = getAuth();

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('Login successful!', userCredential.user);
      // Set cookie with user UID, expires in 90 days
      Cookies.set('uid', userCredential.user.uid, { expires: 90 });
      navigate('/dash');
    } catch (error) {
      const errorMessage = translateError(error);
      toast.error(errorMessage); // Show toast notification
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        fullWidth
        label="Email"
        variant="outlined"
        margin="normal"
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        fullWidth
        label="Password"
        type="password"
        variant="outlined"
        margin="normal"
        required
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        style={{ marginTop: '10px' }}
        disabled={loading} // Disable button when loading
      >
        {loading ? 'Logging in...' : 'Login'} {/* Show loading text */}
      </Button>
    </form>
  );
}

export default Login;
