import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  TextField,
  Button,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { ArrowBack } from '@mui/icons-material';
import { toast } from 'react-hot-toast';
import { getAuth } from 'firebase/auth';

const EditEvent = () => {
  const { eventID } = useParams();
  const navigate = useNavigate();
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false); // To track authentication state

  const auth = getAuth();

  useEffect(() => {
    const checkAuthentication = () => {
      // Check if the user is authenticated
      const user = auth.currentUser;
      setIsAuthenticated(!!user);
    };

    checkAuthentication();

    const fetchEventDetails = async () => {
      try {
        const eventDocRef = doc(db, 'events', eventID);
        const eventDoc = await getDoc(eventDocRef);

        if (eventDoc.exists()) {
          const data = eventDoc.data();
          setEventData(data);
          setName(data.name);
          setDate(data.date);
          setDescription(data.description);
        } else {
          toast.error("Event not found.");
          navigate('/dash/events');
        }
      } catch (error) {
        console.error("Error fetching event data:", error);
        toast.error("Failed to load event details.");
        navigate('/dash/events');
      } finally {
        setLoading(false);
      }
    };

    fetchEventDetails();
  }, [eventID, navigate, auth]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isAuthenticated) {
      toast.error("You must be logged in to edit events.");
      return;
    }

    try {
      const eventDocRef = doc(db, 'events', eventID);
      await updateDoc(eventDocRef, {
        name,
        date,
        description,
      });
      toast.success("Event updated successfully!");
      navigate(`/dash/eventdetails/${eventID}`);
    } catch (error) {
      console.error("Error updating event:", error);
      toast.error("Failed to update event.");
    }
  };

  const handleBackClick = () => {
    navigate(`/dash/eventdetails/${eventID}`); // Navigate back to events list
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleBackClick}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Edit Event
          </Typography>
        </Toolbar>
      </AppBar>

      {loading ? (
        <div style={styles.loaderContainer}>
          <CircularProgress size={80} />
        </div>
      ) : (
        <div style={{ padding: '20px', textAlign: 'center' }}>
          {eventData ? (
            <form onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: '0 auto' }}>
              <TextField
                fullWidth
                label="Event Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                margin="normal"
                required
              />
              <TextField
                fullWidth
                label="Event Date"
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                margin="normal"
                InputProps={{
                  placeholder: 'YYYY-MM-DD', // Custom placeholder
                }}
                required
              />
              <TextField
                fullWidth
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                margin="normal"
                multiline
                rows={4}
              />
              <Button variant="contained" color="primary" type="submit">
                Update Event
              </Button>
            </form>
          ) : (
            <Typography variant="h6" color="error">
              Event not found.
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};

// Styles for fullscreen loading spinner
const styles = {
  loaderContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Slight white background for visibility
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000, // High z-index to appear above other elements
  },
};

export default EditEvent;
