import React, { useState, useEffect } from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    CircularProgress,
    Card,
    CardContent,
    Fab,
    Box,
} from '@mui/material';
import { MoreVert, ArrowDownward, Add } from '@mui/icons-material';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore'; // Import updateDoc to update Firestore
import { db } from '../firebase'; // Ensure your Firestore config is imported
import { toast } from 'react-hot-toast';
import Modal from './Modal'; // Import the Modal component
import Settings from './Settings'; // Import your existing Settings component
import Cookies from 'js-cookie'; // Import js-cookie
import Onboarding from './Onboarding'; // Import Onboarding component

const Dashboard = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true); // Loading state
    const [events, setEvents] = useState([]); // State to store upcoming events
    const [openSettingsModal, setOpenSettingsModal] = useState(false); // State for settings modal
    const [openOnboardingModal, setOpenOnboardingModal] = useState(false); // State for onboarding modal
    const [setIsOnboarded] = useState(false); // Track if the user has completed onboarding
    const navigate = useNavigate();
    const auth = getAuth();

    useEffect(() => {
        const uidCookie = Cookies.get('uid');
        if (!uidCookie) {
            navigate('/'); // Redirect to homepage if uid cookie is not found
            return;
        }

        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                try {
                    const userDocRef = doc(db, 'users', user.uid);
                    const userDoc = await getDoc(userDocRef);
                    if (userDoc.exists()) {
                        const data = userDoc.data();
                        setUserData(data);

                        // Check if onboarding needs to be shown
                        if (!data.isOnboarded) {
                            setOpenOnboardingModal(true); // Open onboarding modal if isOnboarding is false
                        }

                        // Fetch upcoming events
                        const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
                        const eventsQuery = query(
                            collection(db, 'events'),
                            where('creatorUID', '==', user.uid),
                            where('date', '>=', currentDate)
                        );

                        const eventsSnapshot = await getDocs(eventsQuery);
                        const eventsList = eventsSnapshot.docs.map((doc) => ({
                            id: doc.id,
                            ...doc.data(),
                        }));
                        setEvents(eventsList);
                    } else {
                        toast.error("User data not found.");
                    }
                } catch (error) {
                    console.error("Error fetching user data:", error);
                    toast.error("Failed to load user data.");
                } finally {
                    setLoading(false);
                }
            } else {
                navigate('/');
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, [auth, navigate]);

    const handleMoreClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        try {
            Cookies.remove('uid');
            await signOut(auth);
            toast.success("Logged out successfully.");
            navigate('/');
        } catch (error) {
            console.error("Error logging out:", error);
            toast.error("Failed to log out.");
        }
    };

    const handleSettingsClick = () => {
        setOpenSettingsModal(true);
        handleClose();
    };

    const handleCloseSettingsModal = () => {
        setOpenSettingsModal(false);
    };

    const handleCloseOnboardingModal = async () => {
        setOpenOnboardingModal(false);

        // Update Firestore to indicate onboarding is complete
        try {
            const userDocRef = doc(db, 'users', auth.currentUser.uid);
            await updateDoc(userDocRef, { isOnboarded: true });
            setIsOnboarded(true); // Set onboarded state to true
            toast.success("Onboarding complete!");
        } catch (error) {
            console.error("Error updating onboarding status:", error);
            toast.error("Failed to update onboarding status.");
        }
    };

    const displayUsername = loading
        ? 'User'
        : userData
        ? userData.username || auth.currentUser.email
        : 'User';

    const handleScheduleEventClick = () => {
        navigate('/dash/schedule');
    };

    const handleEventCardClick = (eventId) => {
        navigate(`/dash/eventdetails/${eventId}/?source=dash`);
    };

    return (
        <div>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Dashboard
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        aria-label="more"
                        aria-controls="more-menu"
                        aria-haspopup="true"
                        onClick={handleMoreClick}
                    >
                        <MoreVert />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Menu
                id="more-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {userData && (
                    <MenuItem onClick={handleSettingsClick}>
                        <Typography variant="body1" fontWeight="bold">
                            {userData.username || auth.currentUser.email}
                        </Typography>
                    </MenuItem>
                )}
                <MenuItem onClick={handleSettingsClick}>Settings</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>

            {loading ? (
                <div style={styles.loaderContainer}>
                    <CircularProgress size={80} />
                </div>
            ) : (
                <div style={{ padding: '20px', textAlign: 'center' }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Welcome, {displayUsername}!
                    </Typography>

                    <Typography variant="h5" component="h2" gutterBottom>
                        Upcoming Events
                    </Typography>

                    <Box
                        display="flex"
                        flexDirection={{ xs: 'column', md: 'row' }}
                        justifyContent="center"
                        alignItems="center"
                        flexWrap="wrap"
                        marginBottom="20px"
                        sx={{ gap: { xs: '10px', md: '10px' }, padding: { xs: '0 10px', md: '0' } }}
                    >
                        {events.length === 0 ? (
                            <Box
                                sx={{
                                    padding: '20px',
                                    borderRadius: '10px',
                                    border: '1px solid #ccc',
                                    backgroundColor: '#f9f9f9',
                                    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                                }}
                            >
                                <Typography variant="h6" textAlign="center">
                                    No upcoming events.
                                </Typography>
                            </Box>
                        ) : (
                            events.slice(0, 5).map((event) => (
                                <Card
                                    key={event.id}
                                    onClick={() => handleEventCardClick(event.id)}
                                    sx={{
                                        flex: '1 1 calc(20% - 10px)',
                                        maxWidth: '300px',
                                        width: { xs: '90%', md: '100%' },
                                        cursor: 'pointer',
                                        transition: 'transform 0.2s, box-shadow 0.2s',
                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                            boxShadow: '0 4px 20px rgba(0,0,0,0.3)',
                                        },
                                    }}
                                >
                                    <CardContent>
                                        <Typography variant="h6">{event.name}</Typography>
                                        <Typography variant="body2">
                                            {new Date(event.date).toLocaleDateString()}
                                        </Typography>
                                        <Typography variant="body2">{event.description}</Typography>
                                    </CardContent>
                                </Card>
                            ))
                        )}
                    </Box>

                    <IconButton onClick={() => navigate('/dash/events')}>
                        <ArrowDownward fontSize="large" />
                    </IconButton>
                </div>
            )}

            <Fab
                color="primary"
                aria-label="add"
                onClick={handleScheduleEventClick}
                style={{ position: 'fixed', bottom: '20px', right: '20px' }}
            >
                <Add />
            </Fab>

            {/* Onboarding Modal */}
            <Modal open={openOnboardingModal} onClose={handleCloseOnboardingModal} title="Onboarding">
                <Onboarding onComplete={handleCloseOnboardingModal} />
            </Modal>

            <Modal open={openSettingsModal} onClose={handleCloseSettingsModal} title="Settings">
                <Settings />
            </Modal>
        </div>
    );
};

// Styles for fullscreen loading spinner
const styles = {
    loaderContainer: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
};

export default Dashboard;
