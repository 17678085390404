// src/components/Register.js

import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast'; // Import toast
import Cookies from 'js-cookie'; // Import js-cookie
import { getFirestore, doc, setDoc } from 'firebase/firestore'; // Import Firestore functions

const translateError = (error) => {
  switch (error.code) {
    case 'auth/email-already-in-use':
      return "Email is already in use.";
    case 'auth/invalid-email':
      return "Invalid email format.";
    case 'auth/operation-not-allowed':
      return "Email/Password accounts are not enabled.";
    case 'auth/weak-password':
      return "Password should be at least 6 characters.";
    default:
      return "An unexpected error occurred. Please try again.";
  }
};

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // State for confirm password
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    // Check if passwords match
    if (password !== confirmPassword) {
      toast.error("Passwords do not match."); // Show toast notification for mismatch
      setLoading(false); // Stop loading
      return;
    }

    const auth = getAuth();
    const db = getFirestore(); // Initialize Firestore

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      console.log('Registration successful!', userCredential.user);
      // Set cookie with user UID, expires in 90 days
      Cookies.set('uid', userCredential.user.uid, { expires: 90 });
      const user = userCredential.user;

      // Save user details (email and uid) to Firestore
      await setDoc(doc(db, 'users', user.uid), {
        email: user.email,
        uid: user.uid,
        isOnboarded: false
      });

      console.log('User details saved to Firestore.');
      navigate('/dash'); // Redirect to dashboard after successful registration
    } catch (error) {
      const errorMessage = translateError(error);
      toast.error(errorMessage); // Show toast notification
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        fullWidth
        label="Email"
        variant="outlined"
        margin="normal"
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        fullWidth
        label="Password"
        type="password"
        variant="outlined"
        margin="normal"
        required
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <TextField
        fullWidth
        label="Confirm Password"
        type="password"
        variant="outlined"
        margin="normal"
        required
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)} // Handle confirm password input
      />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        style={{ marginTop: '10px' }}
        disabled={loading} // Disable button when loading
      >
        {loading ? 'Registering...' : 'Register'} {/* Show loading text */}
      </Button>
    </form>
  );
}

export default Register;
