// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast'; // Import Toaster and toast
import './firebase.js';
import Welcome from './components/Welcome';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import Settings from './components/Settings'
import EventsPage from './components/EventsPage'
import SchedulePage from './components/SchedulePage'
import EventDetails from './components/EventDetails'
import EditEvent from './components/EditEvent.js'
import Onboarding from "./components/Onboarding";
//import AnimatedPage from "./AnimatedPage";
//import {AnimatePresence} from "framer-motion";

function App() {
  return (
    <Router>
      {/*<AnimatedPage>*/}
        <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/components/modals/login" element={<Login />} />
            <Route path="/components/modals/register" element={<Register />} />
            <Route path='/components/modals/onboarding' element={<Onboarding />} />
            <Route path="/dash" element={<Dashboard />} />
            <Route path="/settings" element={<Settings />} />
            <Route path='/dash/events' element={<EventsPage />} />
            <Route path='/dash/schedule' element={<SchedulePage />} />
            <Route path="/dash/eventdetails/:eventID" element={<EventDetails />} />
            <Route path="/dash/events/edit/:eventID" element={<EditEvent />} />
        </Routes>
        {/*}</AnimatedPage>*/}
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
    </Router>
  );
}

export default App;
