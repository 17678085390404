import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Button, // Import Button component
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { getDoc, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { ArrowBack, Edit, Delete } from '@mui/icons-material'; // Import Edit and Delete icons
import { toast } from 'react-hot-toast';
import { getStorage, ref, deleteObject } from 'firebase/storage'; // Import Firebase Storage functions

const EventDetails = () => {
  const { eventID } = useParams();
  const location = useLocation();
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // State for delete confirmation dialog
  const navigate = useNavigate();

  // Function to get the source parameter from the query string
  const getSource = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('source');
  };

  const handleBackClick = () => {
    const source = getSource();
    navigate(source === 'dash' ? '/dash' : '/dash/events');
  };

  const handleEditClick = () => {
    navigate(`/dash/events/edit/${eventID}`); // Navigate to the edit event page
  };

  // Function to open the delete confirmation dialog
  const handleDeleteClick = () => {
    setOpenDeleteDialog(true); // Open the dialog
  };

  // Function to handle the delete action
  const handleConfirmDelete = async () => {
    try {
      // Delete Firestore document
      const eventDocRef = doc(db, 'events', eventID);
      await deleteDoc(eventDocRef);

      // Delete associated image from Firebase Storage
      if (eventData.photo) {
        const storage = getStorage();
        const imageRef = ref(storage, eventData.photo);
        await deleteObject(imageRef);
      }

      toast.success("Event deleted successfully.");
      navigate('/dash/events'); // Navigate back to events page after deletion
    } catch (error) {
      console.error("Error deleting event:", error);
      toast.error("Failed to delete event.");
    } finally {
      setOpenDeleteDialog(false); // Close the dialog after deletion
    }
  };

  // Function to handle dialog close
  const handleCloseDialog = () => {
    setOpenDeleteDialog(false);
  };

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const eventDocRef = doc(db, 'events', eventID);
        const eventDoc = await getDoc(eventDocRef);

        if (eventDoc.exists()) {
          setEventData(eventDoc.data());
        } else {
          toast.error("Event not found.");
          navigate('/dash/events');
        }
      } catch (error) {
        console.error("Error fetching event data:", error);
        toast.error("Failed to load event details.");
        navigate('/dash/events');
      } finally {
        setLoading(false);
      }
    };

    fetchEventDetails();
  }, [eventID, navigate]);

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleBackClick}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Event Details
          </Typography>
          <IconButton color="inherit" onClick={handleEditClick}>
            <Edit />
          </IconButton>
          <IconButton color="inherit" onClick={handleDeleteClick}>
            <Delete />
          </IconButton>
        </Toolbar>
      </AppBar>

      {loading ? ( // Show fullscreen loading spinner
        <div style={styles.loaderContainer}>
          <CircularProgress size={80} />
        </div>
      ) : (
        <div style={{ padding: '20px', textAlign: 'center' }}>
          {eventData ? (
            <Card style={{ maxWidth: '600px', margin: '0 auto' }}>
              <CardContent>
                <Typography variant="h5" component="h2" gutterBottom>
                  {eventData.name}
                </Typography>
                {/* Display the image from Firebase Storage */}
                {eventData.photo && (
                  <img
                    src={eventData.photo} // Use the "photo" field for the image URL
                    alt={eventData.name}
                    style={{ width: '100%', borderRadius: '8px', marginBottom: '16px' }}
                  />
                )}
                <Typography variant="body1" gutterBottom>
                  <strong>Date:</strong> {eventData.date}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Description:</strong> {eventData.description || 'No description available'}
                </Typography>
              </CardContent>
            </Card>
          ) : (
            <Typography variant="h6" color="error">
              Event not found.
            </Typography>
          )}
        </div>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this event?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

// Styles for fullscreen loading spinner
const styles = {
  loaderContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Slight white background for visibility
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000, // High z-index to appear above other elements
  },
};

export default EventDetails;
