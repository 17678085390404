import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, TextField, Button, CircularProgress, IconButton, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure your Firestore config is imported
import { toast } from 'react-hot-toast';
import { ArrowBack } from '@mui/icons-material';
import { getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage';

const SchedulePage = () => {
  const [eventName, setEventName] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [eventDescription, setEventDescription] = useState('');
  const [eventPhoto, setEventPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();
  const storage = getStorage();

  const handleBackClick = () => {
    navigate('/dash'); // Navigate back to the dashboard
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setEventPhoto(reader.result); // Set the base64 string for preview
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const user = auth.currentUser;
      const userId = user.uid;

      // Create a reference to the storage location
      const storageRef = ref(storage, `events/${Date.now()}_${eventPhoto.name}`);

      // Upload the image to Firebase Storage
      await uploadString(storageRef, eventPhoto, 'data_url');

      // Get the download URL of the uploaded image
      const photoURL = await getDownloadURL(storageRef);

      // Add new event to Firestore with the image URL
      await addDoc(collection(db, 'events'), {
        creatorUID: userId,
        name: eventName,
        date: eventDate,
        description: eventDescription,
        photo: photoURL, // Store the URL in Firestore
      });

      // Reset form fields
      setEventName('');
      setEventDate('');
      setEventDescription('');
      setEventPhoto(null);
      toast.success('Created event');
      navigate('/dash');
    } catch (error) {
      console.error("Error scheduling event:", error);
      toast.error('Failed to create event');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleBackClick}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Schedule Event
          </Typography>
        </Toolbar>
      </AppBar>

      <div style={{ padding: '20px', textAlign: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Schedule Your Event
        </Typography>

        <form onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: '0 auto' }}>
          <TextField
            fullWidth
            variant="outlined"
            label="Event Name"
            value={eventName}
            onChange={(e) => setEventName(e.target.value)}
            required
            margin="normal"
            inputProps={{ maxLength: 100 }}
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Event Date"
            type="date"
            value={eventDate}
            onChange={(e) => setEventDate(e.target.value)}
            required
            margin="normal"
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Event Description"
            value={eventDescription}
            onChange={(e) => setEventDescription(e.target.value)}
            margin="normal"
            multiline
            rows={4}
            inputProps={{ maxLength: 500 }}
          />

          <Box display="flex" justifyContent="center" alignItems="center" style={{ margin: '20px 0' }}>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="raised-button-file"
              type="file"
              onChange={handleImageChange}
            />
            <label htmlFor="raised-button-file">
              <Button variant="outlined" component="span">
                Upload Event Photo
              </Button>
            </label>
          </Box>

          {eventPhoto && (
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Uploaded Photo
              </Typography>
              <img
                src={eventPhoto} // Use the base64 string for preview
                alt="Event Preview"
                style={{ maxWidth: '100%', height: 'auto', marginTop: '10px' }}
              />
            </Box>
          )}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            style={{ marginTop: '20px' }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Schedule Event'}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default SchedulePage;
