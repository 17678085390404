import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Fab,
  IconButton,
} from '@mui/material';
import { Add, ArrowBack } from '@mui/icons-material';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure your Firestore config is imported
import Cookies from 'js-cookie'; // Import js-cookie
import { toast } from 'react-hot-toast';
import { getStorage, ref, deleteObject } from 'firebase/storage'; // Import Storage

const EventsPage = () => {
  const [loading, setLoading] = useState(false); // Loading state
  const [events, setEvents] = useState([]); // State to store all events
  const navigate = useNavigate();
  const auth = getAuth();
  const storage = getStorage(); // Initialize Firebase Storage

  useEffect(() => {

    const deleteEvent = async (eventId, photoURL) => {
      try {
        // Delete the event document from Firestore
        await deleteDoc(doc(db, 'events', eventId));

        // If there is a photoURL, delete the image from Firebase Storage
        if (photoURL) {
          const photoRef = ref(storage, photoURL); // Create a reference to the file to delete
          await deleteObject(photoRef); // Delete the file
        }

//        toast.success("Event and associated image deleted successfully.");
      } catch (error) {
        console.error("Error deleting event:", error);
//        toast.error("Failed to delete event.");
      }
    };

    // Check if uid cookie exists
    const uidCookie = Cookies.get('uid');
    if (!uidCookie) {
      navigate('/'); // Redirect to homepage if uid cookie is not found
      return; // Exit early
    }

    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Fetch all events for the authenticated user
          const eventsQuery = query(
            collection(db, 'events'),
            where('creatorUID', '==', user.uid) // Get events created by this user
          );

          // Fetch documents based on the query
          const eventsSnapshot = await getDocs(eventsQuery);
          const eventsList = eventsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));

          console.log("Fetched events:", eventsList); // Log fetched events for debugging

          // Current date for comparison
          const currentDate = new Date();
          const yesterday = new Date();
          yesterday.setDate(currentDate.getDate() - 1); // Set to yesterday

          // Filter and delete past events (events that are older than yesterday)
          const upcomingEventsPromises = eventsList.map(async (event) => {
            const eventDate = new Date(event.date);
            if (eventDate < yesterday) {
              // Delete the event from Firestore and Storage
              await deleteEvent(event.id, event.photo); // Pass the event photo URL
              return null; // Return null to exclude from upcoming events
            }
            return event; // Include upcoming events
          });

          // Wait for all deletion operations to finish
          const upcomingEvents = (await Promise.all(upcomingEventsPromises)).filter(Boolean);

          // Sort events by date in ascending order
          const sortedEvents = upcomingEvents.sort((a, b) => new Date(a.date) - new Date(b.date));

          // Set the sorted upcoming events
          setEvents(sortedEvents);
        } catch (error) {
          console.error("Error fetching events:", error);
          toast.error("Failed to load events.");
        } finally {
          setLoading(false); // Stop loading once data is fetched
        }
      } else {
        navigate('/'); // Redirect to homepage if no user is authenticated
        setLoading(false); // Stop loading if user is not authenticated
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth, navigate, storage]);

  const handleBackClick = () => {
    navigate('/dash'); // Navigate back to the dashboard
  };

  const handleScheduleEventClick = () => {
    navigate('/dash/schedule'); // Navigate to event scheduling page
  };

  const handleCardClick = (eventID) => {
    navigate(`/dash/eventdetails/${eventID}/?source=events`);
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleBackClick}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Events
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Main content of the Events page */}
      {loading ? ( // Show fullscreen loading spinner
        <div style={styles.loaderContainer}>
          <CircularProgress size={80} />
        </div>
      ) : (
        <div style={{ padding: '20px', textAlign: 'center' }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Your Events
          </Typography>

          {/* Events Section */}
          <div style={{ maxHeight: '70vh', overflowY: 'auto', marginBottom: '20px' }}>
            {events.length === 0 ? (
              <Typography>No events found.</Typography>
            ) : (
              events.map((event) => (
                <Card
                  key={event.id}
                  style={{ margin: '10px 0', textAlign: 'left', cursor: 'pointer' }}
                  onClick={() => handleCardClick(event.id)} // Add onClick to navigate
                >
                  <CardContent>
                    <Typography variant="h6">{event.name}</Typography>
                    <Typography variant="body2">
                      {new Date(event.date).toLocaleDateString()}
                    </Typography>
                    <Typography variant="body2">{event.description}</Typography>
                  </CardContent>
                </Card>
              ))
            )}
          </div>
        </div>
      )}

      {/* Add Event Floating Button */}
      <Fab
        color="primary"
        aria-label="add"
        onClick={handleScheduleEventClick}
        style={{ position: 'fixed', bottom: '20px', right: '20px' }}
      >
        <Add />
      </Fab>
    </div>
  );
};

// Styles for fullscreen loading spinner
const styles = {
  loaderContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Slight white background for visibility
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000, // High z-index to appear above other elements
  },
};

export default EventsPage;
