import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Typography } from '@mui/material';
//import { getAuth } from 'firebase/auth';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Import Firestore config
import { toast } from 'react-hot-toast';
import Cookies from 'js-cookie'; // Make sure to install js-cookie

const Settings = () => {
//  const auth = getAuth();
//  const user = auth.currentUser;
  const [username, setUsername] = useState(''); // For the input field
  const [displayedUsername, setDisplayedUsername] = useState(''); // For the displayed title

  // Get userId from cookie
  const userId = Cookies.get('uid');

  useEffect(() => {
    // Fetch current username when component mounts
    const fetchUserData = async () => {
      try {
        const userDocRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const fetchedUsername = userDoc.data().username || '';
          setUsername(fetchedUsername); // Set the initial input field value
          setDisplayedUsername(fetchedUsername); // Set the displayed username
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        toast.error("Failed to load user data.");
      }
    };

    if (userId) {
      fetchUserData();
    }
  }, [userId]); // Dependency on userId

  const handleUpdate = async () => {
    try {
      await updateDoc(doc(db, 'users', userId), {
        username,
      });
      toast.success("Username updated successfully.");
      setDisplayedUsername(username); // Update displayed username only after successful update
      window.location.reload(); // Refresh the page or you can use a better state management approach
    } catch (error) {
      console.error("Error updating username:", error);
      toast.error("Failed to update username.");
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>{displayedUsername}</Typography>
      <TextField
        label="Username"
        fullWidth
        value={username} // Add value to control the TextField
        onChange={(e) => setUsername(e.target.value)}
        margin="normal"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpdate}
        style={{ marginTop: '20px' }}
      >
        Update
      </Button>
    </Container>
  );
};

export default Settings;
