// src/firebase.js

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC-mmHEqqdVihnn8tInp2RJKqzBbtv3Wqk",
    authDomain: "as-eventura.firebaseapp.com",
    projectId: "as-eventura",
    storageBucket: "as-eventura.appspot.com",
    messagingSenderId: "1048743577691",
    appId: "1:1048743577691:web:0bb872f70e5cfb59fa977e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
