import React, { useState, useEffect } from 'react';
import { TextField, Button, Modal, Box } from '@mui/material';
import { getAuth } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure Firestore is imported
import { toast } from 'react-hot-toast'; // Ensure toast is imported

const Onboarding = () => {
  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);

  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const checkOnboardingStatus = async () => {
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (!userData.isOnboarded) {
            setOpen(true); // Open the modal to prompt onboarding
          }
        }
      }
    };

    checkOnboardingStatus();
  }, [user]);

  const handleSubmit = async () => {
    if (username.trim() === '') {
      toast.error("Username can't be empty");
      return;
    }

    setLoading(true);

    try {
      // Save the username and mark as onboarded
      await setDoc(doc(db, 'users', user.uid), {
        username: username,
        isOnboarded: true,
      }, { merge: true });

      toast.success("Onboarding complete!");
      setOpen(false); // Close modal
      window.location.reload(); // Refresh page after onboarding
    } catch (error) {
      console.error("Error during onboarding:", error);
      toast.error("Failed to save username.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={() => {}} disableBackdropClick>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          textAlign: 'center',
        }}
      >
        <h2>Welcome! Complete your onboarding</h2>
        <TextField
          label="Enter your username"
          fullWidth
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? 'Submitting...' : 'Next'}
        </Button>
      </Box>
    </Modal>
  );
};

export default Onboarding;
