// src/components/Welcome.js

import React, { useState, useEffect } from 'react';
import { Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import Modal from './Modal';
import Login from './Login'; // Importing Login Component
import Register from './Register'; // Importing Register Component
import Cookies from 'js-cookie'; // Import js-cookie

function Welcome() {
  const [openLogin, setOpenLogin] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    // Check if the 'uid' cookie exists
    const uid = Cookies.get('uid');
    if (uid) {
      // Redirect to the dashboard if the cookie is found
      navigate('/dash');
    }
  }, [navigate]);

  const handleLoginOpen = () => setOpenLogin(true);
  const handleLoginClose = () => setOpenLogin(false);

  const handleRegisterOpen = () => setOpenRegister(true);
  const handleRegisterClose = () => setOpenRegister(false);

  return (
    <Container
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      <Typography variant="h2" component="h1" gutterBottom>
        Eventura
      </Typography>
      <div>
        <Button variant="contained" color="primary" onClick={handleLoginOpen} style={{ marginRight: '10px' }}>
          Login
        </Button>
        <Button variant="contained" color="secondary" onClick={handleRegisterOpen}>
          Register
        </Button>
      </div>

      {/* Modal for Login */}
      <Modal open={openLogin} onClose={handleLoginClose} title="Login">
        <Login />
      </Modal>

      {/* Modal for Register */}
      <Modal open={openRegister} onClose={handleRegisterClose} title="Register">
        <Register />
      </Modal>
    </Container>
  );
}

export default Welcome;
